// Debug
//export const CRM_API_URL = 'https://localhost:7066';
//export const INTERNAL_API_URL = 'https://localhost:7028';
//export const TRANS_API_URL = 'https://localhost:7089';

// Development
//export const CRM_API_URL = 'https://crmapi.dev.patronpoints.com';
//export const INTERNAL_API_URL = 'https://internalapi.dev.patronpoints.com';
//export const TRANS_API_URL = 'https://transapi.dev.patronpoints.com';

// Production
export const CRM_API_URL = 'https://crmapi.prod.patronpoints.com';
export const INTERNAL_API_URL = 'https://internalapi.prod.patronpoints.com';
export const TRANS_API_URL = 'https://transapi.prod.patronpoints.com';